var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.headerText))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":""},on:{"click":function($event){_vm.getDocumentExcel(1);
          _vm.document_excel = [];}}},[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)],1),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","server-items-length":_vm.server_items_length,"options":_vm.dataTableOptions,"disable-pagination":true,"footer-props":{
        itemsPerPageOptions: [50, 100, 200, -1],
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items .map(function (x) { return x.id; }) .indexOf(item.id) + _vm.from)+" ")]}},{key:"item.document_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.document_date.substr(0, 10))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.document_status[item.status]["name_" + _vm.$i18n.locale])+" ")]}},{key:"item.all",fn:function(ref){
      var item = ref.item;
return [_vm._l((item.document_signers),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.rozilik",fn:function(ref){
      var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 1) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.tasdiq",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 2) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.yuboruvchi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 3) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.ijro_uchun",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 4) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.malumot_uchun",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 5) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.hujjat_yaratuvchisi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 6) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.empty",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 7) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.komissiya_azolari",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 8) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.komissiya_raisi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 9) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.komissiya_kotibi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 10) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.kuzatuvchi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 11) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.kuzatuvchi_1",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 12) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.tahirlovchi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 13) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.tarqatuvchi",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.document_signers.filter(
            function (v) {
              if (v.action_type_id == 14) { return v; }
            }
          )),function(document_signer,index){return [_c('div',{key:index},[(document_signer.fio)?_c('span',[_vm._v(" "+_vm._s(document_signer.fio)+" ")]):_c('span',[_vm._v(" "+_vm._s(document_signer.employee_staffs.employee[ "firstname_" + _vm.$i18n.locale ].substr(0, 1) + "." + document_signer.employee_staffs.employee[ "middlename_" + _vm.$i18n.locale ].substr(0, 1) + ". " + document_signer.employee_staffs.employee[ "lastname_" + _vm.$i18n.locale ])+" ")])])]})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.$store.getters.checkPermission(item.permission) || true)?_c('v-btn',{attrs:{"color":"success","small":"","icon":"","to":'/document/' + item.pdf_file_name}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.downloadExcel),callback:function ($$v) {_vm.downloadExcel=$$v},expression:"downloadExcel"}},[_c('v-card',[_c('v-card-text',{staticClass:"py-1 px-3"},[_c('v-btn',{staticClass:"mx-10",attrs:{"color":"success","text":""},on:{"click":function($event){_vm.downloadExcel = false}}},[_c('download-excel',{attrs:{"data":_vm.document_excel,"name":_vm.headerText + '_' + _vm.today + '.xls'}},[_c('span',{staticStyle:{"color":"#4caf50"}},[_vm._v(_vm._s(_vm.$t("download")))]),_c('v-icon',{attrs:{"color":"success","height":"20"}},[_vm._v("mdi-download")])],1)],1),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){_vm.downloadExcel = false}}},[_c('v-icon',{attrs:{"color":"error","height":"20"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("loadingText"))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }